var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.lists,"options":_vm.options,"items-per-page":_vm.tableMeta.per_page,"server-items-length":_vm.tableMeta.total,"footer-props":_vm.footer},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){return _vm.$set(_vm.tableMeta, "per_page", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.tableMeta, "per_page", $event)},"dblclick:row":_vm.rowClick},scopedSlots:_vm._u([{key:"item.checked_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.checked_at) .add(543, 'year') .format('D MMMYYYY HH:mm'))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-speed-dial',{attrs:{"direction":"left","open-on-hover":""}},[_c('v-btn',{attrs:{"slot":"activator","color":"primary","dark":"","small":"","fab":"","icon":""},slot:"activator"},[_c('v-icon',[_vm._v("mdi-menu")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","fab":"","color":"blue darken-2","dark":""},on:{"click":function($event){return _vm.$emit('show-pdf', item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-printer")])],1)]}}],null,true)},[_c('span',[_vm._v("พิมพ์ผลการตรวจวัด")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.can.update)?_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.rowClick($event, { item: { id: item.id } })}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pen")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("แก้ไข")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.can.delete)?_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","fab":"","color":"red","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.removeData(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("ลบข้อมูล")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","fab":"","color":"grey","dark":""},on:{"click":function($event){return _vm.logs(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-playlist-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Change Logs")])])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }