<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-toolbar color="indigo darken-3" dark dense flat>
        <v-toolbar-title>
          <v-icon>
            mdi-bottle-wine-outline
          </v-icon>
          รายการตรวจวัดแอลกอฮอล์
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-select
          :items="types"
          label="ค้นหาจาก"
          style="width:40px; !important"
          v-model="type"
          hide-details
          single-line
          dense
          solo-inverted
        ></v-select>
        <v-text-field
          prepend-inner-icon="mdi-magnify"
          width="200px"
          single-line
          hide-details
          dense
          solo-inverted
          label="กรอกคำค้นหา"
          clearable
          v-model="keyword"
          @keypress.enter="getList"
        ></v-text-field>
      </v-toolbar>
      <v-card-text>
        <div class="d-flex">
          <v-btn color="indigo darken-2" dark class="mr-1" @click="newOfficer">
            <v-icon small>
              mdi-plus-box
            </v-icon>
            เพิ่มรายงาน
          </v-btn>
          <v-spacer></v-spacer>
          <v-switch
            v-model="showOver"
            hide-details
            single-line
            :disabled="loading"
            label="แสดง >50mg%"
            inset
            class="mr-4 mt-1 pt-0"
          ></v-switch>
        </div>
      </v-card-text>
      <drunk-table
        :keyword="keyword"
        :type="type"
        :show-over="showOver"
        ref="otable"
        @row-dbl-click="editOfficer"
        @remove-user="deleteOfficer"
        @show-pdf="showPdf"
        @logs="logs"
      ></drunk-table>
    </v-card>

    <!-- dialog -->
    <drunk-dialog ref="odlg" v-model="userDialog" :type="modify" @inserted="onInserted"></drunk-dialog>
    <bjp-confirm-dialog ref="confirm"></bjp-confirm-dialog>
    <pdf-dialog ref="pdf"></pdf-dialog>
    <log-dlg ref="logdlg" v-model="logDialog"></log-dlg>
  </v-container>
</template>

<script>
import drunkTable from './components/drunkTable'
import drunkDialog from './components/drunkDialog'
import pdfDialog from '@/components/pdfDialog'
import logDlg from '@/components/activityList'
import { destroy, getPdf } from '@/api/drunkreport'
export default {
  methods: {
    getList() {
      this.$refs.otable.getList()
    },
    newOfficer() {
      this.$refs.odlg.init(null, 'add')
      this.userDialog = true
    },
    editOfficer(id) {
      this.$refs.odlg.init(id, 'edit')
      this.userDialog = true
    },
    logs(id) {
      this.$refs.logdlg.getLogs('drunkreport', id)
      this.logDialog = true
    },
    async deleteOfficer(id) {
      let dlg = await this.$refs.confirm.open('ลบรายการ', 'คุณต้องการลบรายการนี้ ?')
      if (dlg) {
        this.loading = true
        destroy(id)
          .then(res => {
            // console.log(res)
            if (res.success) {
              this.$toast.success({
                title: 'info',
                message: 'ทำรายการเรียบร้อยแล้ว',
              })
              this.$refs.otable.getList()
            }
          })
          .catch(err => {
            this.$toast.error({
              message: 'ไม่สามารถทำรายการได้',
              title: 'Error',
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    onInserted() {
      this.userDialog = false
      this.$refs.otable.getList()
    },
    async showPdf(id) {
      const pdfBase64 = await getPdf(id)
      this.$refs.pdf.open(pdfBase64.pdf)
    },
  },
  data() {
    return {
      logDialog: false,
      keyword: '',
      userDialog: false,
      type: 'name',
      types: [
        { text: 'ชื่อ-นามสกุล', value: 'name' },
        { text: 'เลข 13 หลัก', value: 'cid' },
        { text: 'ปริมาณแอลกอฮอล์', value: 'alcohol_value' },
      ],
      modify: 'add',
      loading: false,
      showOver: false,
    }
  },
  components: {
    drunkTable,
    drunkDialog,
    pdfDialog,
    logDlg,
  },
}
</script>

<style></style>
